import React, { useState, useRef, useEffect } from "react";

interface FAQItemProps {
  darkMode: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({ darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string | number>("0px");
  const answerRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(answerRef.current?.scrollHeight || "auto");
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div
      className={`${darkMode ? "bg-agiGray" : "border-agiLightGray border"} bg-opacity-50 rounded-lg p-4 flex flex-col cursor-pointer select-none`}
      onClick={toggleOpen}
    >
      <div className="flex justify-between gap-4 w-full">
        <p className="text-lg">Who is behind the AGI Fellowship?</p>
        <svg
          className={`w-6 h-6 ${darkMode ? "text-agiLightGray" : "text-agiLessLightGray"} transform transition-transform origin-center duration-300 ease-in-out ${isOpen ? "" : "rotate-45"}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div
        ref={answerRef}
        style={{
          maxHeight,
          transition: "max-height 0.3s ease-in-out, margin 0.3s ease-in-out",
        }}
        className={`overflow-hidden ${isOpen ? "mt-4" : "mt-0"} ${darkMode ? "text-agiLightGray" : "text-agiLessLightGray"} leading-loose`}
      >
        <p className="text-base">
          ↳ The AGI Fellowship is organized by <a href="https://agihouse.org" target="_blank" rel="noopener noreferrer" className="underline text-blue-500">AGI House</a>, which was officially launched exclusively in Hillsborough, CA on February 1, 2023 by <a href="https://www.linkedin.com/in/rockyyu/" target="_blank" rel="noopener noreferrer" className="underline text-blue-500">Rocky Yu</a> and a group of A-list AI founders and researchers. <a href="https://www.businessinsider.com/heres-how-agi-house-bays-hottest-artificial-intelligence-hacker-2023-6" target="_blank" rel="noopener noreferrer" className="underline text-blue-500">Business Insider</a> and <a href="https://www.economist.com/1843/2023/09/29/who-moved-my-chips-life-in-an-ai-entrepreneurs-houseshare" target="_blank" rel="noopener noreferrer" className="underline text-blue-500">The Economist</a> have written about us. The program is funded by the venture arm of AGI House called AGI House Ventures.
        </p>
      </div>
    </div>
  );
};

export default FAQItem;
