import React, { useState, useRef, useEffect } from "react";

interface FAQItemProps {
  question: string;
  answer: string;
  darkMode: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<string | number>("0px");
  const answerRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(answerRef.current?.scrollHeight || "auto");
    } else {
      setMaxHeight("0px");
    }
  }, [isOpen]);

  return (
    <div
      className={`${darkMode ? "bg-agiGray" : "border-agiLightGray border"}  bg-opacity-50 rounded-lg p-4 flex flex-col cursor-pointer select-none`}
      //  border border-agiGray border-opacity-70
      onClick={toggleOpen}
    >
      <div className="flex justify-between gap-4 w-full">
        <p className="text-lg max-w-[85%]">{question}</p>
        {/* <p className={`text-2xl font-semibold text-agiLightGray transform transition-transform ${isOpen ? 'rotate-45' : ''}`}>+</p> */}
        <svg
          className={`w-6 h-6 ${darkMode ? "text-agiLightGray" : "text-agiLessLightGray"} transform transition-transform origin-center duration-300 ease-in-out ${isOpen ? "" : "rotate-45"}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div
        ref={answerRef}
        style={{
          maxHeight,
          transition: "max-height 0.3s ease-in-out, margin 0.3s ease-in-out",
        }}
        className={`overflow-hidden ${isOpen ? "mt-4" : "mt-0"} ${darkMode ? "text-agiLightGray" : "text-agiLessLightGray"} leading-loose`}
      >
        <p className="text-base">↳ {answer}</p>
      </div>
    </div>
  );
};

export default FAQItem;
